import {ResolveFn, Router} from '@angular/router';
import {BasicDataDto, SimulationService, WorkingWidthDto} from '../../../generated/backend';
import {inject} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {catchError, EMPTY} from 'rxjs';

/**
 * Lädt die Basis-Daten
 */
export const basicDataResolver: ResolveFn<BasicDataDto> = () => {
  const router = inject(Router);
  const simulationService = inject(SimulationService);
  const translocoService = inject(TranslocoService);
  return simulationService.getBasicData(translocoService.getActiveLang())
    .pipe(
      catchError(() => {
        router.navigate(['/error'], {queryParamsHandling: 'preserve'});
        return EMPTY;
      }));
};

/**
 * Lädt die Arbeitsbreiten
 */
export const workingWidthsResolver: ResolveFn<WorkingWidthDto> = () => {
  const router = inject(Router);
  const simulationService = inject(SimulationService);
  return simulationService.getWorkingWidths()
    .pipe(
      catchError(() => {
        router.navigate(['/error'], {queryParamsHandling: 'preserve'});
        return EMPTY;
      }));
};
