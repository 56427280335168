import {Component, Input} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';

/**
 * Komponente für die Info-Anzzeige
 */
@Component({
  selector: 'streu-info-anzeige',
  templateUrl: './info-anzeige.component.html',
  styleUrls: ['./info-anzeige.component.scss']
})
export class InfoAnzeigeComponent {
  @Input() einstellungen?: SimulationsEinstellungen;
}
