import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {BasicDataDto, SimulationService, WorkingWidthDto} from '../../../../generated/backend';
import {TranslocoService} from '@ngneat/transloco';
import {DropdownChangeEvent} from 'primeng/dropdown';
import {WertePaar} from '../../types/wertepaar';
import {MessageService} from 'primeng/api';

/**
 * Komponente ermöglicht die Auswahl von Sprache, Einheitensystem, Dünger und Arbeitsbreite
 */
@Component({
  selector: 'streu-auswahl',
  templateUrl: './auswahl.component.html',
  styleUrls: ['./auswahl.component.scss']
})
export class AuswahlComponent implements OnInit {
  @Input() basicData?: BasicDataDto;
  @Input() workingWidths?: WorkingWidthDto;
  @Input() loading = false;
  @Output() starteSimulationEreignis = new EventEmitter<SimulationsEinstellungen>();
  locale = '';
  anfangsLocale = '';

  duengerResponsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 6,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  einheiten: Array<WertePaar> = [];
  duengerKlassen: Array<WertePaar> = [];
  arbeitsbreiten?: Array<WertePaar> = [];

  selektierterDuenger?: WertePaar;
  selektierteEinheit?: WertePaar;
  selektierteArbeitsbreite?: WertePaar;
  sprachen: Array<WertePaar> = [{wert: 'de', name: 'Deutsch'}];
  selektierteSprache?: string;
  private selektierteEinheitId = 0;

  constructor(private translocoService: TranslocoService,
              private messageService: MessageService,
              private simulationService: SimulationService) {
  }

  ngOnInit() {
    this.locale = this.translocoService.getActiveLang();
    this.anfangsLocale = this.translocoService.getActiveLang();

    this.datenVorbereiten();
    // Gespeicherte Einstellungen versuchen zu laden
    const stringData = localStorage.getItem('SBS_DATA');
    if (stringData) {
      const einstellungen: SimulationsEinstellungen = JSON.parse(stringData);
      this.selektierteEinheit = this.einheiten.find(e => e.wert.toLowerCase() === einstellungen.einheitenSystemWert.toLowerCase());
      this.selektierterDuenger = einstellungen.duengerklasse;
      this.selektierteArbeitsbreite = einstellungen.arbeitsbreite;
      this.einheitenGeaendert({value: this.selektierteEinheit} as DropdownChangeEvent);
    } else {
      // Standard Auswahl setzen
      this.selektierteEinheit = this.einheiten[0];
      if (this.arbeitsbreiten) {
        this.selektierteArbeitsbreite = this.arbeitsbreiten[0];
      }
    }
  }

  /**
   * Setzt den selektierten Dünger
   * @param duenger
   */
  selektiereDuenger(duenger: WertePaar) {
    this.selektierterDuenger = duenger;
    this.ladeArbeitsbreiten();
  }

  /**
   * Prüft ob der Dünger selektiert ist. Wird vom Carousel genutzt.
   * @param auswahl ausgewählter Dünger
   */
  istDuengerSelektiert(auswahl: WertePaar): boolean {
    return auswahl.wert === this.selektierterDuenger?.wert;
  }

  /**
   * Wurden alle notwendigen Auswahlmöglichkeiten getroffen
   */
  istAuswahlVollstaendig() {
    const vollstaendig = !istNull(this.selektierterDuenger) &&
      !istNull(this.selektierteEinheit) &&
      !istNull(this.selektierteArbeitsbreite);
    return vollstaendig;
  }

  /**
   * Einheitensystem wurde geändert, neue Arbeitsbreiten vorschlagen
   * @param event Ereignis
   */
  einheitenGeaendert(event: DropdownChangeEvent) {
    const selectItem = event.value as WertePaar;
    this.ladeArbeitsbreiten();
    this.setzeEinheitenId(selectItem);
  }



  /**
   * Sprache wurde geändert, Auswahlwerte neu bestimmen.
   * @param event Ereignis
   */
  spracheGeaendert(event: DropdownChangeEvent) {
    this.translocoService.load(event.value).subscribe(() => {
      localStorage.setItem('SBS_LANG', event.value);
      this.translocoService.setActiveLang(event.value);
      this.datenVorbereiten();
      this.locale = this.translocoService.getActiveLang();

      this.selektierterDuenger = this.duengerKlassen.find(p => p.wert === this.selektierterDuenger?.wert);
    });
  }

  /**
   * Starte die Simulation und navigiere auf die nächste Seite.
   * Einstellungen werden im Local-Storage abgelegt.
   */
  starteSimulation() {
    if (this.selektierteEinheit && this.selektierterDuenger && this.selektierteArbeitsbreite) {
      this.loading = true;

      this.setzeEinheitenId(this.selektierteEinheit);

      this.simulationService.getBaseSettings(
        this.selektierteEinheitId,
        parseInt(this.selektierteArbeitsbreite.wert),
        parseInt(this.selektierterDuenger.wert)
      ).subscribe({
        next: (response) => {
          const einstellungen: SimulationsEinstellungen = {
            einheitenSystem: this.selektierteEinheitId,
            einheitenSystemWert: this.selektierteEinheit?.wert ?? 'metric',
            duengerklasse: this.selektierterDuenger,
            duengerqualitaet: {wert: '0', name: ''},
            arbeitsbreite: this.selektierteArbeitsbreite,
            streuscheibe: response,
            defaultDropPoint: response.dropPoint ?? 30,
            argustwin: false,
            windrichtung: 'west',
            windgeschwindigkeit: 0,
            windgeschwindigkeitIndex: 0,
            windrichtungId: 3
          };
          this.starteSimulationEreignis.emit(einstellungen);
        }, error: () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translocoService.translate('messages.connection_title'),
            detail: this.translocoService.translate('messages.connection')
          });
          this.loading = false;
        }
      });
    }
  }

  sprachKuerzel(sprache: string): string {
    return sprache.split('-')[0];
  }

  private ladeArbeitsbreiten() {
    if (this.selektierterDuenger) {
      this.loading = true;
      this.simulationService.getWorkingWidths(Number(this.selektierterDuenger.wert)).subscribe({
        next: (response) => {
          this.loading = false;
          this.workingWidths = response;
          if (this.workingWidths && this.workingWidths.unit && this.selektierteEinheit) {
            const unit = this.workingWidths.unit[this.selektierteEinheit.wert].unit;
            const widthsArray = this.workingWidths.unit[this.selektierteEinheit.wert].workingWidths;
            this.arbeitsbreiten = widthsArray?.map(w => {
              return {
                wert: String(w),
                name: `${w} ${unit}`
              };
            });
            if (this.arbeitsbreiten && this.selektierteArbeitsbreite) {
              const enthalten = this.arbeitsbreiten.map(a => a.wert).includes(this.selektierteArbeitsbreite.wert);
              if (!enthalten) {
                this.selektierteArbeitsbreite = this.findeNaechstKleinereArbeitsbreite(this.selektierteArbeitsbreite, this.arbeitsbreiten);
                console.log(this.selektierteArbeitsbreite);
              }
            }
            if (this.arbeitsbreiten && !this.selektierteArbeitsbreite) {
              this.selektierteArbeitsbreite = this.arbeitsbreiten[0];
            }
          }
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translocoService.translate('messages.connection_title'),
            detail: this.translocoService.translate('messages.connection')
          });
          this.loading = false;
        }
      });
    }
  }


  private datenVorbereiten() {
    // Sprachen auslesen
    this.sprachen = this.translocoService.getAvailableLangs().map(l => {
      return {
        wert: String(l),
        name: this.translocoService.translate('lang.' + this.sprachKuerzel(String(l)))
      };
    }).filter(f => !f.wert.includes('-'));

    if (this.anfangsLocale.includes('-')) {
      const index = this.sprachen.findIndex(el => el.wert === this.sprachKuerzel(this.anfangsLocale));
      this.sprachen[index].wert = this.anfangsLocale;
    }

    // Düngerklassen aus den BasicData extrahieren
    if (this.basicData && this.basicData.fertilizerGroups) {

      this.selektierteSprache = this.translocoService.getActiveLang();

      this.duengerKlassen = Object.entries(this.basicData.fertilizerGroups).map(([schluessel, name]) => {
        return {
          name: this.translocoService.translate('fertilizer.' + name),
          wert: schluessel
        };
      });
    }

    // Einheit aus den Arbeitsbreiten extrahieren und standard Arbeitsbreiten setzen
    if (this.basicData && this.basicData.rawUnits) {
      this.einheiten = Object.values(this.basicData.rawUnits).map(value => {
        return {
          name: this.translocoService.translate('unit.' + value),
          wert: value
        };
      });
      this.einheitenGeaendert({value: this.einheiten[0]} as DropdownChangeEvent);
    }
  }

  private setzeEinheitenId(selectItem: WertePaar) {
    if (selectItem && this.basicData && this.basicData.rawUnits) {
      Object.entries(this.basicData.rawUnits).forEach(([schluessel, name]) => {
        if (name.toLowerCase() === selectItem.wert.toLowerCase()) {
          this.selektierteEinheitId = parseInt(schluessel);
        }
      });
    }
  }

  private findeNaechstKleinereArbeitsbreite(selektierteArbeitsbreite: WertePaar, arbeitsbreiten: Array<WertePaar>): WertePaar | undefined {
    // Sortiere die Liste der Arbeitsbreiten nach dem Wert in aufsteigender Reihenfolge.
    arbeitsbreiten.sort((a, b) => parseFloat(a.wert) - parseFloat(b.wert));

    // Konvertiere die selektierte Arbeitsbreite in eine Zahl.
    const selektierteWert = parseFloat(selektierteArbeitsbreite.wert);

    // Durchlaufe die sortierte Liste und finde die nächst kleinere Arbeitsbreite.
    for (let i = arbeitsbreiten.length - 1; i >= 0; i--) {
      const aktuelleArbeitsbreite = parseFloat(arbeitsbreiten[i].wert);

      if (aktuelleArbeitsbreite < selektierteWert) {
        // Die aktuelle Arbeitsbreite ist kleiner. Du könntest sie hier zurückgeben,
        // wenn du sicherstellen möchtest, dass sie die nächst kleinere ist.
        return arbeitsbreiten[i];
      }
    }

    // Wenn keine kleinere Arbeitsbreite gefunden wurde, kannst du undefined zurückgeben.
    return undefined;
  }
}

/**
 * Hilfsfunktion um zu bestimmen ob Felder gefüllt sind.
 * @param objekt Wert
 */
function istNull(objekt: unknown) {
  return objekt === null || objekt === undefined || objekt === '';
}
