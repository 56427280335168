import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

interface LoadingRequest {
  status: boolean;
  timer: number;
}


/**
 * Service um zu vermerkten welche Lade-Anfragen sich gerade in der Verarbeitung befinden.
 * Der Zustand des wirdGeladen BehaviorSubjectes ändert sich erst, wenn die Anfrage länger als 1 Sekunde dauert.
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingRequestService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private requestMap: Map<string, LoadingRequest> = new Map<string, LoadingRequest>();


  /**
   * Setzen ob eine URL gerade geleden wird
   * @param loading Wahr oder Falsch: Wird geladen?
   * @param url Angefragte URL
   */
  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading && !this.requestMap.has(url)) {
      const timer: unknown = setTimeout(() => {
        this.loading.next(true);
      }, environment.loadingTimeout);
      this.requestMap.set(url, {status: loading, timer: timer as number});
    } else if (!loading && this.requestMap.has(url)) {
      const anfrage = this.requestMap.get(url);
      clearTimeout(anfrage?.timer);
      this.requestMap.delete(url);
    }
    if (this.requestMap.size === 0) {
      this.requestMap.delete(url);
      this.loading.next(false);
    }
  }
}
