import {Component, HostListener, OnInit} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {ActivatedRoute, Router} from '@angular/router';
import {BasicDataDto, WorkingWidthDto} from '../../../../generated/backend';
import {EventbusService} from '../../core/eventbus.service';
import {LoadingRequestService} from '../../core/services/loading-request.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

/**
 * Komponente für die Simulationsseite.
 */
@Component({
  selector: 'streu-simulation-seite',
  templateUrl: './simulation-seite.component.html',
  styleUrls: ['./simulation-seite.component.scss']
})
export class SimulationSeiteComponent implements OnInit {
  einstellungen?: SimulationsEinstellungen;

  basicData?: BasicDataDto;
  workingWidths?: WorkingWidthDto;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cmsMode = false;
  menuEingeklappt = false;
  menuNichtRendern = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private loadingRequestService: LoadingRequestService,
              private eventBus: EventbusService) {
  }


  ngOnInit() {
    this.cmsMode = this.activatedRoute.snapshot.queryParams['cmsMode'];
    this.loading = this.loadingRequestService.loading;

    this.basicData = this.activatedRoute.snapshot.data['basicData'];
    this.workingWidths = this.activatedRoute.snapshot.data['workingWidths'];

    const data = localStorage.getItem('SBS_DATA');
    if (data) {
      const einstellungen = JSON.parse(data);
      this.starteSimulation(einstellungen);
    } else {
      this.router.navigate(['/choose'], {queryParamsHandling: 'preserve'});
    }
    this.onResize();
    window.scrollTo(0,0);
  }

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth <= environment.layout.mobileBreakPoint) {
      this.menuEingeklappt = true;
    } else {
      this.menuEingeklappt = false;
    }
    // Dieser scheiß Fix, ist nur wegen Safari notwendig....
    setTimeout(() => {this.menuNichtRendern = this.menuEingeklappt;}, 500);
  }

  /**
   * Navigiere auf die Auswahl-Seite
   */
  bearbeiten() {
    localStorage.setItem('SBS_DATA', JSON.stringify(this.einstellungen));
    this.router.navigate(['/choose'], {queryParamsHandling: 'preserve'});
  }

  /**
   * Starte die Simulation
   * @param event
   */
  starteSimulation(event: SimulationsEinstellungen) {
    this.einstellungen = event;
    this.eventBus.einstellungenGeaendertEvent.next(event);
  }
}
