import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {BasicDataDto} from '../../../../generated/backend';
import {TranslocoService} from '@ngneat/transloco';
import {EventbusService} from '../../core/eventbus.service';
import {FachlogikService} from '../../core/services/fachlogik.service';

/**
 * Windrichtungen
 */
type Windrichtungen = 'north' | 'south' | 'east' | 'west';

/**
 * Komponente für die Windsteuerung
 */
@Component({
  selector: 'streu-windsteuerung',
  templateUrl: './windsteuerung.component.html',
  styleUrls: ['./windsteuerung.component.scss']
})
export class WindsteuerungComponent implements OnInit {
  @Input() einstellungen?: SimulationsEinstellungen;
  @Input() basicData?: BasicDataDto;
  einheitensystem = '';
  maxSchritte = 4;

  constructor(private renderer: Renderer2,
              private eventbus: EventbusService,
              private fachlogikService: FachlogikService,
              private translocoService: TranslocoService) {
  }

  ngOnInit() {
    if (this.einstellungen && this.basicData) {
      const container = this.basicData.windSpeeds?.find(ws => ws.key === this.einstellungen?.einheitenSystemWert);
      if (container && container.speeds && container.unit) {
        this.maxSchritte = container.speeds.length - 1;
        this.einheitensystem = this.translocoService.translate('unit.' + container.unit);
      }
      this.rotiereWindpfeil(this.einstellungen.windrichtung as Windrichtungen);
    }
  }

  /**
   * Windrichtung ändern, Pfeil muss gedreht werden. Eventbus mit neuen Einstellungen triggern.
   * @param richtung Windrichtung
   */
  aendereWindrichtung(richtung: Windrichtungen) {
    if (this.einstellungen && this.basicData && this.basicData.windDirections) {
      this.rotiereWindpfeil(richtung);

      Object.entries(this.basicData.windDirections).forEach(([schluessel, name]) => {
        if (richtung === name && this.einstellungen) {
          this.einstellungen.windrichtungId = parseInt(schluessel);
        }
      });


      this.fachlogikService.windGeaendert(this.einstellungen);
    }
  }

  /**
   * Erhöht die Windgeschwindigkeit. Eventbus mit neuen Einstellungen triggern.
   */
  erhoheWindgeschindigkeit() {
    if (this.einstellungen && this.basicData) {
      const container = this.basicData.windSpeeds?.find(ws => ws.key === this.einstellungen?.einheitenSystemWert);
      // Index zurücksetzen, wenn Windgeschwindigkeit 0 ist
      if (this.einstellungen.windgeschwindigkeit === 0) {
        this.einstellungen.windgeschwindigkeitIndex = 0;
      }
      if (container && container.speeds && container.unit) {
        this.einheitensystem = this.translocoService.translate('unit.' + container.unit);
        this.einstellungen.windgeschwindigkeitIndex = this.einstellungen.windgeschwindigkeitIndex >= container.speeds.length - 1 ? container.speeds.length - 1 : this.einstellungen.windgeschwindigkeitIndex + 1;
        this.einstellungen.windgeschwindigkeit = container.speeds[this.einstellungen.windgeschwindigkeitIndex];

        this.fachlogikService.windGeaendert(this.einstellungen);
      }
    }
  }

  /**
   * Bremst die Windgeschwindigkeit. Eventbus mit neuen Einstellungen triggern.
   */
  bremseWindgeschwindigkeit() {
    if (this.einstellungen && this.basicData) {
      const container = this.basicData.windSpeeds?.find(ws => ws.key === this.einstellungen?.einheitenSystemWert);
      if (container && container.speeds && container.unit) {
        this.einheitensystem = this.translocoService.translate('unit.' + container.unit);
        this.einstellungen.windgeschwindigkeitIndex = this.einstellungen.windgeschwindigkeitIndex <= 0 ? 0 : this.einstellungen.windgeschwindigkeitIndex - 1;

        this.einstellungen.windgeschwindigkeit = container.speeds[this.einstellungen.windgeschwindigkeitIndex];

        this.fachlogikService.windGeaendert(this.einstellungen);
      }
    }
  }

  private rotiereWindpfeil(richtung: Windrichtungen) {
    if (this.einstellungen) {
      this.einstellungen.windrichtung = richtung;
      let rotation: number;
      switch (richtung) {
        case 'north':
          rotation = 180;
          break;
        case 'south':
          rotation = 0;
          break;
        case 'east':
          rotation = 90;
          break;
        case 'west':
          rotation = -90;
          break;
        default:
          rotation = 0;
      }

      this.renderer.setStyle(document.querySelector('.wind-pfeil'),
        'transform', `rotate(${rotation}deg)`);
    }
  }
}
