import {Component, OnInit} from '@angular/core';
import {StatusService} from '../../../../generated/backend';
import {interval, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

/**
 * Komponente für die Fehlerseite. Wenn der SBS-Service wieder verfügbar ist, wird automatisch auf die Start-Seite navigiert.
 */
@Component({
  selector: 'streu-error',
  templateUrl: './error-seite.component.html',
  styleUrls: ['./error-seite.component.scss']
})
export class ErrorSeiteComponent implements OnInit {
  private source = interval(5000);
  private subscriber?: Subscription;
  cmsMode = false;

  constructor(private statusService: StatusService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.cmsMode = this.activatedRoute.snapshot.queryParams['cmsMode'];
    this.subscriber = this.source.subscribe(() => {
      this.statusService.ping().subscribe(() => {
        this.subscriber?.unsubscribe();
        this.router.navigate(['/'], {queryParamsHandling: 'preserve'});
      });
    });
  }
}
