<div id="aufgabepunkt" style="position: relative" class="m-2">
  <ng-container *transloco="let t">
    <label>{{t('droppoint.header')}}</label>
  </ng-container>

  <div class="drehscheiben">
    <div style="position: relative">
      <img src="assets/images/drehscheibe_punkt.png" class="drehscheibe-punkt" alt="Punkt links">
      <img src="assets/images/drehscheibe.png" class="drehscheibe-gespiegelt" alt="Drehscheibe links">
    </div>
    <div style="position: relative">
      <img src="assets/images/drehscheibe_punkt.png" class="drehscheibe-punkt-gespiegelt" alt="Punkt rechts">
      <img src="assets/images/drehscheibe.png" class="drehscheibe" alt="Drehscheibe rechts">
    </div>
    <div class="aufgabepunkt-buttons text-center">
      <div>{{einstellungen?.streuscheibe?.dropPoint}}</div>
      <p-button icon="pi pi-plus" styleClass="p-button-secondary"
                (onClick)="erhoeheAufgabepunkt()" [disabled]="this.einstellungen?.streuscheibe?.dropPoint === 60"></p-button>
      <p-button icon="pi pi-minus" styleClass="p-button-secondary"
                (onClick)="senkeAufgabepunkt()" [disabled]="this.einstellungen?.streuscheibe?.dropPoint === 0"></p-button>
    </div>
  </div>

  <div style="width: 150px" *ngIf="einstellungen" class="ml-2">

    <p-slider #slider [ngModel]="einstellungen.streuscheibe.dropPoint"
              (onChange)="aufgabepunktGeaendert(slider.value)" [min]="0" [max]="60"
              styleClass="slider"></p-slider>
    <p-slider [ngModel]="optimalerAufgabepunkt()"
              [disabled]="true" [min]="0" [max]="60"
              styleClass="default-slider"></p-slider>
  </div>

  <p-divider></p-divider>
</div>
