<ng-container *transloco="let t">
  <div class="flex justify-content-center align-content-start flex-wrap container"
       [ngClass]="[cmsMode? 'cmsModeMargin': 'background']" style="min-height: 100vh;">
    <div class="flex align-items-center justify-content-center flex-column">
      <div [style]="{width: '95vw'}" class="rounded surface-0 shadow-4 m-2 p-2">
        <p-divider [style]="{padding: 0, margin: 0}">
          <div class="inline-flex align-items-center">
            <i class="pi pi-pencil mr-2"></i>
            <b>{{t('settings.title_choose')}}</b>
          </div>
        </p-divider>
        <streu-info-anzeige [einstellungen]="einstellungen"></streu-info-anzeige>
      </div>
      <div [style]="{width: '95vw'}" class="rounded surface-0 shadow-4 m-2 p-2">
        <p-divider [style]="{padding: 0, margin: 0}">
          <div class="inline-flex align-items-center">
            <i class="pi pi-sliders-h mr-2"></i>
            <b>{{t('settings.title_settings')}}</b>
          </div>
        </p-divider>
        <streu-aufgabepunkt [einstellungen]="einstellungen"></streu-aufgabepunkt>
        <streu-duenger-qualitaet [einstellungen]="einstellungen"
                                 [basicData]="basicData">
        </streu-duenger-qualitaet>
        <streu-argustwin [einstellungen]="einstellungen"></streu-argustwin>
        <streu-windsteuerung [einstellungen]="einstellungen"
                             [basicData]="basicData">
        </streu-windsteuerung>
        <p-divider></p-divider>

        <p-button [label]="t('info.confirm')" styleClass="col-12" (onClick)="anwenden()"
                  icon="pi pi-check-circle"
                  [loading]="loading"></p-button>
      </div>

    </div>
  </div>
</ng-container>
