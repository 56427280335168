import { Environment } from '../app/types/environment';

export const environment: Environment = {
  apiPath: 'https://app-spreadpatternsim-backend-dev.azurewebsites.net',
  baseRef: '/',
  layout: {
    mobileBreakPoint: 800,
  },
  loadingTimeout: 1000,
  debounceTime: 200,
  name: 'dev',
  production: false,
};
