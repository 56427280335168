import {Component, Input, Renderer2} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {FachlogikService} from '../../core/services/fachlogik.service';
import {EventbusService} from '../../core/eventbus.service';

@Component({
  selector: 'streu-argustwin',
  templateUrl: './argustwin.component.html',
  styleUrls: ['./argustwin.component.scss']
})
export class ArgustwinComponent {
  @Input() einstellungen?: SimulationsEinstellungen;

  constructor(private renderer: Renderer2,
              private fachlogikService: FachlogikService,
              private eventBus: EventbusService) {
  }
  /**
   * Argus Twin wurde geändert, rotiert die Drehscheiben.
   * Setzt die Windgeschwindkeit zurück.
   */
  argusTwinGeaendert() {
    if (this.einstellungen) {
      this.fachlogikService.argusTwinGeaendert(this.einstellungen);

      this.rotiere();
    }
  }

  /**
   * Rotiert die Drehscheiben
   * @private
   */
  private rotiere() {
    if (this.einstellungen && document.querySelector('.drehscheibe-punkt')) {
      this.renderer.setStyle(document.querySelector('.drehscheibe-punkt'),
        'transform' , `rotate(${this.einstellungen.streuscheibe.dropPoint}deg)`);
      this.renderer.setStyle(document.querySelector('.drehscheibe-punkt-gespiegelt'),
        'transform' , `rotate(-${this.einstellungen.streuscheibe.dropPoint}deg) scaleX(-1)`);
    }
  }
}
