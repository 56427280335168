import {ResolveFn} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

/**
 * Container für Bilder
 */
export interface Image {
  image: HTMLImageElement,
  scale: boolean,
  origin: string,
  shrinkFactor?: number,
  x: number,
  y: number
}


/**
 * Lädt das Hintergrundbild
 */
export const backgroundImageResolver: ResolveFn<Image> = () => {
  return loadImage('feld_horizont2.png', true, 'origin', 0, 0);
};


/**
 * Lädt ein Bild
 * @param imagePath Pfad zum Bild
 * @param scale soll skaliert werden
 * @param origin wo soll das Bild angezeigt werden
 * @param x X-Position
 * @param y Y-Position
 * @param shrinkFactor Faktor um Größe zu verändern
 */
function loadImage(imagePath: string, scale: boolean, origin: string, x: number, y: number, shrinkFactor?: number): Observable<Image> {
  return new Observable(function (observer) {
    const img = new Image();
    img.src = environment.baseRef + 'assets/images/' + imagePath;
    img.onload = function () {
      observer.next({
        image: img,
        scale: scale,
        origin: origin,
        x: x,
        y: y,
        shrinkFactor: shrinkFactor
      });
      observer.complete();
    };
    img.onerror = function (err) {
      observer.error(err);
    };
  });
}
