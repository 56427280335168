import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslocoService} from '@ngneat/transloco';
import {filter, map, mergeMap, of} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {routeTransitionAnimations} from './animations/router-animation';

/**
 * Allgemeine Komponente für die Anwendung
 */
@Component({
  selector: 'streu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent implements OnInit {

  constructor(private primengConfig: PrimeNGConfig,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private translocoService: TranslocoService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).pipe(mergeMap((data: string | null) => {
      if (data) {
        return translocoService.selectTranslate(data);
      }
      return of(null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })).subscribe((data: any) => {
      if (data) {
        // Titel setzen
        this.titleService.setTitle(data);
      }
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;

    // Lang aus den QueryParams lesen und als Sprache setzen
    this.activatedRoute.queryParamMap.subscribe(params => {
      let lang = params.get('lang');
      if (lang) {
        const langEnthalten = (this.translocoService.getAvailableLangs() as string[]).includes(lang);
        if (langEnthalten) {
          this.translocoService.setActiveLang(lang);
        }
      } else {
        lang = localStorage.getItem('SBS_LANG');
        if (lang) {
          const langEnthalten = (this.translocoService.getAvailableLangs() as string[]).includes(lang);
          if (langEnthalten) {
            this.translocoService.setActiveLang(lang);
          }
        }
      }
    });
  }

  /**
   * Router Transitionen animieren
   * @param outlet
   */
  prepareRouteTransition(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
