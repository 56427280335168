import {Component, OnInit} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {BasicDataDto, WorkingWidthDto} from '../../../../generated/backend';
import {ActivatedRoute, Router} from '@angular/router';

/**
 * Komponente für die Einstellungsseite. Wird nur in der Mobilen-Ansicht genutzt.
 */
@Component({
    selector: 'streu-einstellungen-page',
    templateUrl: './einstellungen-page.component.html',
    styleUrls: ['./einstellungen-page.component.scss']
})
export class EinstellungenPageComponent implements OnInit {
    cmsMode = false;
    einstellungen?: SimulationsEinstellungen;
    basicData?: BasicDataDto;
    workingWidths?: WorkingWidthDto;

    loading = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.cmsMode = this.activatedRoute.snapshot.queryParams['cmsMode'];
        this.basicData = this.activatedRoute.snapshot.data['basicData'];
        this.workingWidths = this.activatedRoute.snapshot.data['workingWidths'];

        const data = localStorage.getItem('SBS_DATA');
        if (data) {
            this.einstellungen = JSON.parse(data);

        } else {
            this.router.navigate(['/choose'], {queryParamsHandling: 'preserve'});
        }
    }

  /**
   * Startet die Simulation mit neuen Einstellungen
   */
  anwenden() {
        localStorage.setItem('SBS_DATA', JSON.stringify(this.einstellungen));
        this.router.navigate(['/simulation'], {queryParamsHandling: 'preserve'}).then(() => this.loading = false);
    }
}
