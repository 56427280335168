import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LoadingRequestService} from '../services/loading-request.service';
import {finalize, Observable} from 'rxjs';

/**
 * Vermeldet ob HTTP-Anfrage laufen und die Anwendung Daten lödt.
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingRequestService) {
  }

  /**
   * Fängt eine HTTP Verbindung ab, bevor diese Ausgeführt wird und vermeldet, dass geladen wird.
   * Bei Fehlern oder Beenden der Verbindung wird der Status zurückgesetzt.
   * @param request Anfrage
   * @param next Handler
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loadingService.setLoading(true, request.url);

    return next.handle(request).pipe(
      finalize(() => this.loadingService.setLoading(false, request.url)),
    );
  }
}
