<div id="argustwinpanel" style="position: relative" class="m-2">
  <ng-container *transloco="let t">
    <div class="mt-2" *ngIf="einstellungen">
      <div class="field grid">
        <label for="argustwin" class="col-fixed">{{t('droppoint.argus_twin')}}</label>
        <div class="col text-right">
          <p-inputSwitch id="argustwin" [(ngModel)]="einstellungen.argustwin"
                         (onChange)="argusTwinGeaendert()"></p-inputSwitch>
        </div>
      </div>
    </div>
  </ng-container>
  <p-divider></p-divider>
</div>
