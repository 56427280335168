/**
 * Streubild-Simulation API : 0.1.6
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object to store infos about the setting
 */
export interface SettingDto { 
    /**
     * Property to store the name of the disc
     */
    disc?: string | null;
    /**
     * Property to store the disc speed
     */
    discSpeed?: number;
    /**
     * Property to store the unit of the disc speed
     */
    unit?: string | null;
    /**
     * Property to store the default position for the \"Einleitsystem\"
     */
    dropPoint?: number;
    /**
     * Property to store the default disc type for the setting
     */
    discType?: number;
}

