/**
 * Streubild-Simulation API : 0.1.6
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Container class for the payload which will be delivered to the frontend
 */
export interface WorkingWidthContainer { 
    /**
     * Property to determine the unit value as string for this object
     */
    unit?: string | null;
    /**
     * Property to store the value into a list for this object
     */
    workingWidths?: Array<number> | null;
}

