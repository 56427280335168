<ng-container *transloco="let t">
  <div class="simpage">
    <div [ngClass]="[menuEingeklappt ? 'fade-in-left' : 'fade-out-left', cmsMode ? 'burgermenue-cms' : 'burgermenue']">
      <p-button icon="pi pi-sliders-h" routerLink="/settings" queryParamsHandling="preserve"></p-button>
    </div>
    <div class="seitenmenue surface-50 shadow-4 ml-1"
         [ngClass]="[menuEingeklappt ? 'fade-out-left' : 'fade-in-left', cmsMode? 'cmsModeMargin': 'mt-1']"
         *ngIf="!menuNichtRendern" style="position: absolute">
      <streu-info-anzeige [einstellungen]="einstellungen"></streu-info-anzeige>
      <streu-aufgabepunkt [einstellungen]="einstellungen"></streu-aufgabepunkt>
      <streu-duenger-qualitaet [einstellungen]="einstellungen"
                               [basicData]="basicData">
      </streu-duenger-qualitaet>
      <streu-argustwin [einstellungen]="einstellungen"></streu-argustwin>
      <streu-windsteuerung [einstellungen]="einstellungen"
                           [basicData]="basicData">
      </streu-windsteuerung>
    </div>
    <div class="simulation">
      <streu-simulation [menuEingeklappt]="menuEingeklappt"></streu-simulation>
    </div>
  </div>
  <span [ngClass]="cmsMode ? 'loader-cms' : 'loader'" *ngIf="loading | async"></span>
</ng-container>
