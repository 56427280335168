/**
 * Streubild-Simulation API : 0.1.6
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkingWidthContainer } from './workingWidthContainer';


/**
 * Object to store information about working widths and units
 */
export interface WorkingWidthDto { 
    /**
     * Property to store the different combinations for the working widths
     */
    unit?: { [key: string]: WorkingWidthContainer; } | null;
}

