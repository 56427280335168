import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SimulationSeiteComponent} from './pages/simulation-seite/simulation-seite.component';
import {basicDataResolver, workingWidthsResolver} from './resolver/auswahl-resolver';
import {ErrorSeiteComponent} from './pages/error/error-seite.component';
import {AuswahlSeiteComponent} from './pages/auswahl-seite/auswahl-seite.component';
import {backgroundImageResolver} from './resolver/simulation-resolver';
import {EinstellungenPageComponent} from './pages/einstellungen-page/einstellungen-page.component';

/**
 * Routen der Anwendungen
 */
const routes: Routes = [
    {
        path: 'choose',
        component: AuswahlSeiteComponent,
        data: {
            title: 'simulation',
            animation: 'One'
        },
        resolve: {basicData: basicDataResolver, workingWidths: workingWidthsResolver}
    },
    {
        path: 'settings',
        component: EinstellungenPageComponent,
        data: {
            title: 'simulation',
            animation: 'Two'
        },
        resolve: {
            basicData: basicDataResolver,
            workingWidths: workingWidthsResolver,
        }
    },
    {
        path: 'simulation',
        component: SimulationSeiteComponent,
        data: {
            title: 'simulation',
            animation: 'Three'
        },
        resolve: {
            basicData: basicDataResolver,
            workingWidths: workingWidthsResolver,
            background: backgroundImageResolver
        }
    },
    {
        path: 'error',
        component: ErrorSeiteComponent,
        data: {
            title: 'simulation'
        }
    },

    // Allgemeine Route, lenkt alle unbekannten Anfragen auf die Startseite
    {path: '**', redirectTo: 'choose'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
