import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownModule} from 'primeng/dropdown';
import {PaginatorModule} from 'primeng/paginator';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {CarouselModule} from 'primeng/carousel';
import {RippleModule} from 'primeng/ripple';
import {SliderModule} from 'primeng/slider';
import {ProgressBarModule} from 'primeng/progressbar';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {DividerModule} from 'primeng/divider';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ChipModule} from 'primeng/chip';
import {TooltipModule} from 'primeng/tooltip';

/**
 * Modul für PrimeNG Komponenten
 */
@NgModule({
    declarations: [],
    exports: [
        RippleModule,
        CarouselModule,
        ChipModule,
        DialogModule,
        DividerModule,
        CardModule,
        ButtonModule,
        SliderModule,
        DropdownModule,
        PaginatorModule,
        ProgressBarModule,
        InputSwitchModule,
        ToastModule,
        TooltipModule
    ],
    imports: [
        CommonModule
    ],
    providers: [
        MessageService
    ]
})
export class PrimengModule {
}
