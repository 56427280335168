<div class="cmsMode" *ngIf="cmsMode"></div>
<div class="text-center"
     style="background: radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%);">
  <span class="bg-white text-pink-500 font-bold text-2xl inline-block px-3">500</span></div>

<div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
  <ng-container *transloco="let t">
    <div [innerHTML]="t('messages.servererror_title')"></div>
  </ng-container>
</div>
<p class="text-700 text-3xl mt-0 mb-6 text-center">
  <ng-container *transloco="let t">
    <div [innerHTML]="t('messages.servererror')"></div>
  </ng-container>
</p>

<div class="text-center">
  <div style="width: 50%; margin: auto">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px'}"></p-progressBar>
  </div>
</div>

