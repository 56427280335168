import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {debounceTime, Subject, Subscription} from 'rxjs';
import {EventbusService} from '../../core/eventbus.service';
import {SimulationService} from '../../../../generated/backend';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {MessageService} from 'primeng/api';
import {TranslocoService} from '@ngneat/transloco';
import {Image} from '../../resolver/simulation-resolver';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

/**
 * Layer Struktur, besteht aus Vordergrund, Datenschicht und Hintergrund
 */
export interface DrawLayer {
  background?: Image,
  data?: Image
}

/**
 * Komponente zur Visualisierung des Streubildes
 */
@Component({
  selector: 'streu-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnInit, OnChanges {
  @ViewChild('canvas', {static: true}) canvas?: ElementRef<HTMLCanvasElement>;
  @Input() menuEingeklappt = false;

  private ctx?: CanvasRenderingContext2D | null;
  private layer: DrawLayer = {};
  private ratio = 0.5;
  private shrinkFactor = 0.18;
  private resizeSubject: Subject<void> = new Subject<void>();
  private streubildSubject: Subject<void> = new Subject<void>();
  private vorherigeAnfrage?: Subscription;
  private einstellungen?: SimulationsEinstellungen;

  constructor(private eventBus: EventbusService,
              private activatedRoute: ActivatedRoute,
              private messageService: MessageService,
              private translocoService: TranslocoService,
              private simulationService: SimulationService) {
  }

  ngOnInit(): void {
    this.ctx = this.canvas?.nativeElement.getContext('2d');

    this.layer.background = this.activatedRoute.snapshot.data['background'];

    // Neue Streubilder mit einem Debounce laden
    this.streubildSubject.asObservable().pipe(debounceTime(environment.debounceTime))
      .subscribe(() => this.ladeStreubild());

    // Beim Resize neue Bilder laden
    this.resizeSubject.asObservable()
      .subscribe(() => {
        this.draw();
        this.streubildSubject.next();
      });

    // An den Eventbus anhängen und auf neue Einstellungen lauschen
    this.eventBus.einstellungenGeaendertEvent
      .subscribe(einstellungen => {
        this.einstellungen = einstellungen;
        this.streubildSubject.next();
      });

    // Einmal alles berechnen
    this.resize();
  }

  // Wenn das Menü eingeklappt wurde, einmal die Bilder neu berechnen
  ngOnChanges(changes: SimpleChanges) {
    if (changes['menuEingeklappt']) {
      this.resize();
    }
  }

  /**
   * Resize Funktion um neue Größen zu berechnen
   */
  resize() {
    if (this.ctx) {
      if (this.menuEingeklappt) {
        this.ctx.canvas.width = window.innerWidth;
      } else {
        this.ctx.canvas.width = window.innerWidth;
      }

      this.ctx.canvas.height = window.innerHeight - 4;
      this.resizeSubject?.next();
    }
  }

  /**
   * Zeichne alle Ebenen
   */
  async draw() {
    if (this.ctx && this.canvas) {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

      if (this.layer.background) {
        this.renderAsset(this.layer.background);
      }
      if (this.layer.data) {
        this.renderAsset(this.layer.data);
      }
    }
  }

  /**
   * Lade anhand der Einstellungen ein neues Streubild
   * @private
   */
  private ladeStreubild() {
    if (this.einstellungen && this.ctx) {
      this.vorherigeAnfrage?.unsubscribe();
      const shrink = Math.floor(this.ctx.canvas.height * this.shrinkFactor);

      this.vorherigeAnfrage = this.simulationService.getDistribution(
        this.ctx.canvas.width,
        this.ctx.canvas.height - shrink,
        parseInt(this.einstellungen.arbeitsbreite?.wert ?? '0'),
        this.einstellungen.einheitenSystem,
        parseInt(this.einstellungen.duengerklasse?.wert ?? '0'),
        this.einstellungen.streuscheibe.dropPoint,
        parseInt(this.einstellungen.duengerqualitaet?.wert ?? '0'),
        this.einstellungen.windgeschwindigkeit,
        this.einstellungen.argustwin,
        this.einstellungen.windrichtungId
      ).subscribe({
        next: response => {
          const image = new Image();
          image.onload = () => this.draw();
          image.src = response;
          this.layer.data = {
            image: image,
            scale: true,
            origin: 'origin',
            x: 0,
            y: 0,
            shrinkFactor: this.shrinkFactor
          };
        }, error: () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translocoService.translate('messages.connection_title'),
            detail: this.translocoService.translate('messages.connection')
          });
        }
      });
    }
  }

  /**
   * Render ein Asset
   * @param asset Asset
   * @private
   */
  private renderAsset(asset: Image) {
    const imgWidth = this.canvas?.nativeElement.width ?? asset.image.width;
    const imgHeight = this.canvas?.nativeElement.height ?? asset.image.height;
    if (asset.scale) {
      if (asset.shrinkFactor) {
        const shrink = imgHeight * asset.shrinkFactor;
        this.ctx?.drawImage(asset.image, asset.x, asset.y + shrink, imgWidth, imgHeight - shrink);
      } else {
        this.ctx?.drawImage(asset.image, asset.x, asset.y, imgWidth, imgHeight);
      }
    } else {
      if (this.ctx && this.canvas) {
        this.ctx.save();
        const neuWidth = asset.image.width * this.ratio;
        const neuHeight = asset.image.height * this.ratio;
        const neuX = (this.canvas.nativeElement.width / 2) - (neuWidth / 2);
        const neuY = this.canvas.nativeElement.height / 2 - (neuHeight);

        switch (asset.origin) {
          case 'center':
            this.ctx.translate(neuX, neuY);
            this.ctx.shadowOffsetX = 3;
            this.ctx.shadowOffsetY = 6;
            this.ctx.shadowColor = 'rgba(0,0,0,0.6)';
            this.ctx.shadowBlur = 10;
            break;
          case 'bottom':
            this.ctx.translate(this.canvas.nativeElement.width / 2, this.canvas.nativeElement.height);
            break;
          default:
            break;
        }

        this.ctx.drawImage(asset.image, asset.x, asset.y, neuWidth, neuHeight);
        this.ctx.restore();
      }
    }
  }
}
