/**
 * Streubild-Simulation API : 0.1.6
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object to store all info for a single wind speed typed container
 */
export interface WindSpeedContainer { 
    /**
     * Property to store the type of unit for the wind speed
     */
    unit?: string | null;
    /**
     * Property to store the key for the translation and unit selection
     */
    key?: string | null;
    /**
     * Property to store the actual values
     */
    speeds?: Array<number> | null;
}

