<ng-container *transloco="let t">
    <div id="duenger" class="w-full p-2 pt-2">
        <div class="info-anzeige-small mb-2">
            <div class="flex streu-label">
                <div class="flex-1 icon-cell">
                  <img ngSrc="assets/images/duengerklasse.svg" height="25" width="25"
                       [alt]="t('info.fertilizer')" [pTooltip]="t('info.fertilizer')">
                    <span>{{einstellungen?.duengerklasse?.name}}</span>
                </div>
                <div class="flex-1 icon-cell" style="white-space: nowrap">
                  <img ngSrc="assets/images/arbeitsbreite.svg" height="25" width="25"
                       [alt]="t('info.working_width')" [pTooltip]="t('info.working_width')">
                    <span>{{einstellungen?.arbeitsbreite?.name}}</span>
                </div>
                <div class="flex-1 icon-cell" style="white-space: nowrap">
                  <img ngSrc="assets/images/streuscheibe.svg" height="25" width="25"
                       [alt]="t('info.disc')" [pTooltip]="t('info.disc')">
                  <span>{{einstellungen?.streuscheibe?.disc}}</span>
                </div>
                <div class="flex-1 icon-cell" style="white-space: nowrap">
                  <img ngSrc="assets/images/streuscheibendrehszahl.svg" height="25" width="25"
                       [alt]="t('info.speed')" [pTooltip]="t('info.speed')">
                    <span>{{einstellungen?.streuscheibe?.discSpeed}}
                        <small>{{einstellungen?.streuscheibe?.unit}}</small></span>
                </div>
            </div>
        </div>
        <div class="info-anzeige-large">
            <div class="field grid mb-0">
              <img ngSrc="assets/images/duengerklasse.svg" height="20" width="20"
                   [alt]="t('info.fertilizer')" [pTooltip]="t('info.fertilizer')">
                <label class="col-fixed">{{t('info.fertilizer')}}</label>
                <div class="col text-right">
                  {{einstellungen?.duengerklasse?.name}}
                </div>
            </div>
            <div class="field grid mb-0">
              <img ngSrc="assets/images/arbeitsbreite.svg" height="20" width="20"
                   [alt]="t('info.working_width')" [pTooltip]="t('info.working_width')">
                <label class="col-fixed">{{t('info.working_width')}}</label>
                <div class="col text-right">
                  {{einstellungen?.arbeitsbreite?.name}}
                </div>
            </div>
            <div class="field grid mb-0">
              <img ngSrc="assets/images/streuscheibe.svg" height="20" width="20"
                   [alt]="t('info.disc')" [pTooltip]="t('info.disc')">
                <label class="col-fixed">{{t('info.disc')}}</label>
                <div class="col text-right">
                  {{einstellungen?.streuscheibe?.disc}}
                </div>
            </div>
            <div class="field grid mb-0">
              <img ngSrc="assets/images/streuscheibendrehszahl.svg" height="20" width="20"
                   [alt]="t('info.speed')" [pTooltip]="t('info.speed')">
                <label class="col-fixed">{{t('info.speed')}}</label>
                <div class="col text-right">
                  {{einstellungen?.streuscheibe?.discSpeed}} {{einstellungen?.streuscheibe?.unit}}
                </div>
            </div>
        </div>
      <p-button [label]="t('info.action')" styleClass="mb-1 col-12 p-button-secondary p-button-outlined"
                icon="pi pi-arrow-circle-left"
                routerLink="/choose" queryParamsHandling="preserve"></p-button>
        <p-divider [style]="{padding: 0, margin: 0}"></p-divider>
    </div>
</ng-container>
