<ng-container *transloco="let t">
    <div class="formgrid">
        <div class="field">
            <label for="sprache" class="streu-label">
              <img ngSrc="assets/images/sprache.svg" height="25" width="25" [alt]="t('choose_dialog.language')">
              {{t('choose_dialog.language')}}
            </label>
            <p-dropdown id="sprache" styleClass="w-full"
                        appendTo="body"
                        (onChange)="spracheGeaendert($event)"
                        [options]="sprachen" [(ngModel)]="selektierteSprache" optionLabel="name" optionValue="wert">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selektierteSprache">
                  <img [src]="'assets/images/flags/'+sprachKuerzel(selektierteSprache)+'.png'" [alt]="t('lang.'+sprachKuerzel(selektierteSprache))" style="width: 28px; height: 16px"/>
                  <div>{{t('lang.'+sprachKuerzel(selektierteSprache))}}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img [src]="'assets/images/flags/'+sprachKuerzel(country.wert)+'.png'" [alt]="t('lang.'+sprachKuerzel(country.wert))" style="width: 28px; height: 16px" />
                  <div>{{ country.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
        </div>


        <div class="field">
            <label for="einheiten" class="streu-label">
              <img ngSrc="assets/images/einheitensystem_1.svg" height="25" width="25" [alt]="t('choose_dialog.unit')">
              {{t('choose_dialog.unit')}}
            </label>
            <p-dropdown id="einheiten" styleClass="w-full"
                        appendTo="body"
                        (onChange)="einheitenGeaendert($event)"
                        [options]="einheiten" [(ngModel)]="selektierteEinheit" optionLabel="name" dataKey="wert">
            </p-dropdown>
        </div>

        <div class="flex flex-column gap-2">
            <label for="duengerklasse" class="streu-label">
              <img ngSrc="assets/images/duengerklasse.svg" height="25" width="25" [alt]="t('choose_dialog.fertilízer')">
              {{t('choose_dialog.fertilízer')}}
            </label>
            <p-carousel id="duengerklasse" [value]="duengerKlassen" [numVisible]="8" [numScroll]="1"
                        [responsiveOptions]="duengerResponsiveOptions">
                <ng-template let-duenger pTemplate="item">
                    <div pRipple
                         class="green border-1 surface-border border-round m-2 text-center py-5 px-3 auswahl-box font-bold"
                         (click)="selektiereDuenger(duenger)"
                         [ngClass]="istDuengerSelektiert(duenger)? 'selektiert': ''">
                        {{duenger.name}}
                    </div>
                </ng-template>
            </p-carousel>
        </div>

      <div class="field">
        <label for="arbeitsbreite" class="streu-label">
          <img ngSrc="assets/images/arbeitsbreite.svg" height="25" width="25" [alt]="t('choose_dialog.working_width')">
          {{t('choose_dialog.working_width')}}
        </label>
        <p-dropdown id="arbeitsbreite" styleClass="w-full"
                    appendTo="body"
                    [disabled]="arbeitsbreiten?.length === 0"
                    [options]="arbeitsbreiten" [ngModel]="selektierteArbeitsbreite" (onChange)="selektierteArbeitsbreite = $event.value"
                    optionLabel="name">
        </p-dropdown>
      </div>
        <p-button [label]="t('choose_dialog.action')" styleClass="xl:col-4 xl:col-offset-4 md:col-6 md:col-offset-3 col-12"
                  [loading]="loading"
                  (onClick)="starteSimulation()" [disabled]="!istAuswahlVollstaendig()"></p-button>
        <div class="flex justify-content-center align-content-center mt-4 flex-wrap">
            <div class="flex align-items-center justify-content-center">
                <div>
                    <span class="pi pi-question-circle mr-1"></span>
                    <label [innerHTML]="t('choose_dialog.link')"></label>
                    <label [innerHTML]="t('choose_dialog.link_' + locale)"></label>
                </div>
            </div>
        </div>
    </div>
</ng-container>
