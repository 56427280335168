import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslocoRootModule} from './transloco-root.module';
import {AuswahlComponent} from './components/auswahl/auswahl.component';
import {SimulationSeiteComponent} from './pages/simulation-seite/simulation-seite.component';
import {SimulationComponent} from './components/simulation/simulation.component';
import {WindsteuerungComponent} from './components/windsteuerung/windsteuerung.component';
import {AufgabepunktComponent} from './components/aufgabepunkt/aufgabepunkt.component';
import {DuengerQualitaetComponent} from './components/duenger-qualitaet/duenger-qualitaet.component';
import {InfoAnzeigeComponent} from './components/info-anzeige/info-anzeige.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PrimengModule} from './primeng.module';
import {BASE_PATH} from '../../generated/backend';
import {environment} from '../environments/environment';
import {ErrorSeiteComponent} from './pages/error/error-seite.component';
import {LoadingInterceptor} from './core/interceptor/loading';
import {AuswahlSeiteComponent} from './pages/auswahl-seite/auswahl-seite.component';
import {EinstellungenPageComponent} from './pages/einstellungen-page/einstellungen-page.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgOptimizedImage} from '@angular/common';
import {ArgustwinComponent} from './components/argustwin/argustwin.component';

@NgModule({
  declarations: [
    AppComponent,
    AuswahlComponent,
    SimulationSeiteComponent,
    SimulationComponent,
    WindsteuerungComponent,
    AufgabepunktComponent,
    DuengerQualitaetComponent,
    InfoAnzeigeComponent,
    ErrorSeiteComponent,
    AuswahlSeiteComponent,
    EinstellungenPageComponent,
    ArgustwinComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        PrimengModule,
        TranslocoRootModule,
      // Service Worker für Caching
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        NgOptimizedImage
    ],
  providers: [
    {
      provide: BASE_PATH, useValue: environment.apiPath
    },
    // Loading protokollieren
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
