<div id="wind" class="windeinstellungen m-2" style="position: relative">
  <ng-container *transloco="let t">
    <label>{{t('wind.header')}}</label>
  </ng-container>
  <img src="assets/images/arrow.svg" alt="Windrichtung" class="wind-pfeil">
  <div class="set">
    <div class="d-pad">
      <a class="up" [ngClass]="einstellungen?.windrichtung === 'north' ? 'active': ''"
         (click)="aendereWindrichtung('north')"></a>
      <a class="right" [ngClass]="einstellungen?.windrichtung === 'west' ? 'active': ''"
         (click)="aendereWindrichtung('west')"></a>
      <a class="down" [ngClass]="einstellungen?.windrichtung === 'south' ? 'active': ''"
         (click)="aendereWindrichtung('south')"></a>
      <a class="left" [ngClass]="einstellungen?.windrichtung === 'east' ? 'active': ''"
         (click)="aendereWindrichtung('east')"></a>
    </div>
  </div>
  <div class="windgeschwindigkeit text-center">
    <span style="white-space: nowrap;">{{einstellungen?.windgeschwindigkeit}} {{einheitensystem}}</span>
    <p-button icon="pi pi-plus" styleClass="p-button-secondary" (onClick)="erhoheWindgeschindigkeit()" [disabled]="einstellungen?.windgeschwindigkeitIndex === maxSchritte"></p-button>
    <p-button icon="pi pi-minus" styleClass="p-button-secondary" (onClick)="bremseWindgeschwindigkeit()" [disabled]="einstellungen?.windgeschwindigkeitIndex === 0"></p-button>
  </div>
</div>

