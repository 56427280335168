import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {Nullable} from 'primeng/ts-helpers';
import {EventbusService} from '../../core/eventbus.service';
import {FachlogikService} from '../../core/services/fachlogik.service';

/**
 * Komponente für den Aufgabepunkt
 */
@Component({
  selector: 'streu-aufgabepunkt',
  templateUrl: './aufgabepunkt.component.html',
  styleUrls: ['./aufgabepunkt.component.scss']
})
export class AufgabepunktComponent implements OnInit {
  @Input() einstellungen?: SimulationsEinstellungen;

  constructor(private renderer: Renderer2,
              private fachlogikService: FachlogikService,
              private eventBus: EventbusService) {
  }

  ngOnInit() {
    this.rotiere();
    this.eventBus.einstellungenGeaendertEvent.subscribe(() => {
      this.rotiere();
    });
  }

  /**
   * Aufgabepunkt wurde geändert, Drehscheibe muss rotiert werden
   * @param value der neue Aufgabepunkt
   */
  aufgabepunktGeaendert(value: Nullable<number>) {
    if (this.einstellungen) {
      this.einstellungen.streuscheibe.dropPoint = Number(value);
      this.fachlogikService.aufgabepunktGeaendert(this.einstellungen);
    }
    this.rotiere();
  }

  /**
   *  Erhöht den Aufgabepunkt und rotiert die Drehscheibe
   */
  erhoeheAufgabepunkt() {
    if (this.einstellungen && this.einstellungen.streuscheibe.dropPoint !== undefined && this.einstellungen.streuscheibe.dropPoint >= 0) {
      this.einstellungen.streuscheibe.dropPoint += 1;
      if (this.einstellungen.streuscheibe.dropPoint >= 60) {
        this.einstellungen.streuscheibe.dropPoint = 60;
      }
      this.fachlogikService.aufgabepunktGeaendert(this.einstellungen);
    }
    this.rotiere();
  }

  /**
   * Senkt den Aufgabepunkt und rotiert die Drehscheibe
   */
  senkeAufgabepunkt() {
    if (this.einstellungen && this.einstellungen.streuscheibe.dropPoint) {
      this.einstellungen.streuscheibe.dropPoint -= 1;
      if (this.einstellungen.streuscheibe.dropPoint <= 0) {
        this.einstellungen.streuscheibe.dropPoint = 0;
      }
      this.fachlogikService.aufgabepunktGeaendert(this.einstellungen);
    }
    this.rotiere();
  }


  /**
   * Rotiert die Drehscheiben
   * @private
   */
  private rotiere() {
    if (this.einstellungen && document.querySelector('.drehscheibe-punkt')) {
      this.renderer.setStyle(document.querySelector('.drehscheibe-punkt'),
        'transform', `rotate(${this.einstellungen.streuscheibe.dropPoint}deg)`);
      this.renderer.setStyle(document.querySelector('.drehscheibe-punkt-gespiegelt'),
        'transform', `rotate(-${this.einstellungen.streuscheibe.dropPoint}deg) scaleX(-1)`);
    }
  }

  optimalerAufgabepunkt(): number {
    if (this.einstellungen) {
      return this.einstellungen.defaultDropPoint - (Number(this.einstellungen.duengerqualitaet?.wert) ?? 0);
    }
    return 0;
  }
}
