import {Component, OnInit} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {ActivatedRoute, Router} from '@angular/router';
import {BasicDataDto, WorkingWidthDto} from '../../../../generated/backend';

/**
 * Komponente für die Auswahl-Seite
 */
@Component({
    selector: 'streu-auswahl-seite',
    templateUrl: './auswahl-seite.component.html',
    styleUrls: ['./auswahl-seite.component.scss']
})
export class AuswahlSeiteComponent implements OnInit {
    basicData?: BasicDataDto;
    workingWidths?: WorkingWidthDto;
    loading = false;
    cmsMode = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.basicData = this.activatedRoute.snapshot.data['basicData'];
        this.workingWidths = this.activatedRoute.snapshot.data['workingWidths'];
        this.cmsMode = this.activatedRoute.snapshot.queryParams['cmsMode'];
    }

  /**
   * Startet die Simulation, Einstellungen werden im Local-Storage gespeichert.
   * @param event
   */
  starteSimulation(event: SimulationsEinstellungen) {
        localStorage.setItem('SBS_DATA', JSON.stringify(event));
        this.router.navigate(['/simulation'], {queryParamsHandling: 'preserve'}).then(() => this.loading = false);
    }
}
