<ng-container *transloco="let t">
  <div class="flex justify-content-center align-content-start flex-wrap container" style="min-height: 100vh;"
       [ngClass]="cmsMode ? '': 'background'">
    <div class="flex align-items-center justify-content-center mt-2">
      <p-card [style]="{width: '95vw'}" [header]="cmsMode ? t('app_title'): ''" [ngClass]="cmsMode? 'cmsModeMargin': ''">
        <ng-template pTemplate="header">
          <div class="amazone-logo" style="position:relative; width: 300px;" *ngIf="!cmsMode">
            <img
              src="assets/images/amazone_logo.svg"
              alt="Logo">
            <div class="text text-right text-xl font-bold">{{t('app_title')}}</div>
          </div>
        </ng-template>
        <streu-auswahl (starteSimulationEreignis)="starteSimulation($event)"
                       [basicData]="basicData"
                       [loading]="loading"
                       [workingWidths]="workingWidths">
        </streu-auswahl>
      </p-card>
    </div>
  </div>

</ng-container>
