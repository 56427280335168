<div id="qualitaet" class="m-2">
  <ng-container *transloco="let t">
    <label>{{t('fertilizer.header')}}</label>
  </ng-container>
  <div class="field" *ngIf="einstellungen">
    <p-dropdown id="einheiten" styleClass="w-full" appendTo="body"
                [options]="duengerqualitaeten" [(ngModel)]="selektierteDuengerqualitaet" optionLabel="name"
                (onChange)="duengerqualitaetGeaendert($event)">
    </p-dropdown>
  </div>
  <p-divider></p-divider>
</div>
