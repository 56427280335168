import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SimulationsEinstellungen} from '../types/simulations-einstellungen';

/**
 * EventBus für anwendungsweite Ereignisse
 */
@Injectable({
  providedIn: 'root'
})
export class EventbusService {
  einstellungenGeaendertEvent: BehaviorSubject<SimulationsEinstellungen | undefined> = new BehaviorSubject<SimulationsEinstellungen | undefined>(undefined);
  duengerQualitaetAufSehrGutEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
