import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {WertePaar} from '../../types/wertepaar';
import {BasicDataDto} from '../../../../generated/backend';
import {TranslocoService} from '@ngneat/transloco';
import {DropdownChangeEvent} from 'primeng/dropdown';
import {EventbusService} from '../../core/eventbus.service';
import {FachlogikService} from '../../core/services/fachlogik.service';

/**
 * Komponente für die Düngerqualitöt
 */
@Component({
  selector: 'streu-duenger-qualitaet',
  templateUrl: './duenger-qualitaet.component.html',
  styleUrls: ['./duenger-qualitaet.component.scss']
})
export class DuengerQualitaetComponent implements OnInit{
  @Input() einstellungen?: SimulationsEinstellungen;
  @Input() basicData?: BasicDataDto;
  duengerqualitaeten: Array<WertePaar> = [];
  selektierteDuengerqualitaet?: WertePaar;
  @Output() duengerqualitaetGeaendertEreignis = new EventEmitter<void>();

  constructor(private translocoService: TranslocoService,
              private fachlogikService: FachlogikService,
              private eventBus: EventbusService) {
  }

  ngOnInit() {
    if (this.basicData?.fertilizerQuality) {
      this.duengerqualitaeten = Object.entries(this.basicData?.fertilizerQuality).map(([schluessel, name]) => {
        return {
          name: this.translocoService.translate('fertilizer.' + name),
          wert: schluessel
        };
      });
      this.selektierteDuengerqualitaet = this.duengerqualitaeten.find(d => d.wert === this.einstellungen?.duengerqualitaet?.wert);
    }
    this.eventBus.duengerQualitaetAufSehrGutEvent.subscribe(value =>  {
      if (value && this.einstellungen) {
        this.einstellungen.duengerqualitaet = this.duengerqualitaeten.at(0);
        this.selektierteDuengerqualitaet = this.duengerqualitaeten.at(0);
      }
    });
  }

  /**
   * Dünger wurde geändert, Eventbus triggern -> es gibt neue Einstellungen
   * @param event Ereignis
   */
  duengerqualitaetGeaendert(event: DropdownChangeEvent) {
    if (this.einstellungen) {
      this.einstellungen.duengerqualitaet = (event.value as WertePaar);
      this.fachlogikService.duengerqualitaetGeaendert(this.einstellungen);
    }
  }
}
