/**
 * Streubild-Simulation API : 0.1.6
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WindSpeedContainer } from './windSpeedContainer';


/**
 * Object to handle all types of basic data for the front end application
 */
export interface BasicDataDto { 
    /**
     * Property to store the types of fertilizer quality
     */
    fertilizerQuality?: { [key: string]: string; } | null;
    /**
     * Property to store the types of wind direction
     */
    windDirections?: { [key: string]: string; } | null;
    /**
     * Property to store the different wind speeds for the frontend
     */
    windSpeeds?: Array<WindSpeedContainer> | null;
    /**
     * Property to store the fertilizer groups which are possible
     */
    fertilizerGroups?: { [key: string]: string; } | null;
    /**
     * Raw type of unit system
     */
    rawUnits?: { [key: string]: string; } | null;
}

