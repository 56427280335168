import {Injectable} from '@angular/core';
import {SimulationsEinstellungen} from '../../types/simulations-einstellungen';
import {EventbusService} from '../eventbus.service';

/**
 * Kapselt die Fachlogik der Oberfläche
 */
@Injectable({
  providedIn: 'root'
})
export class FachlogikService {
  constructor(private eventBus: EventbusService) {
  }

  /**
   * Wenn Aufgabepunkt geändert,
   * dann Düngerqualität nicht ändern
   * und Wind auf 0 setzen
   * und Argus Twin deaktivieren
   * @param einstellungen
   */
  aufgabepunktGeaendert(einstellungen: SimulationsEinstellungen): void {
    einstellungen.argustwin = false;
    einstellungen.windgeschwindigkeit = 0;
    einstellungen.windgeschwindigkeitIndex = 0;

    this.eventBus.einstellungenGeaendertEvent.next(einstellungen);
  }

  /**
   * Wenn Düngerqualität geändert wird,
   * dann Wind auf 0 setzen
   * und Aufgabepunkt auf optimal setzen
   * @param einstellungen
   */
  duengerqualitaetGeaendert(einstellungen: SimulationsEinstellungen): void {
    einstellungen.windgeschwindigkeit = 0;
    einstellungen.windgeschwindigkeitIndex = 0;

    if (einstellungen.argustwin) {
      const duengerqualitaetWert = einstellungen.duengerqualitaet ? Number(einstellungen.duengerqualitaet.wert) : 0;
      einstellungen.streuscheibe.dropPoint = this.berechneAufgabepunkt(einstellungen.defaultDropPoint, duengerqualitaetWert);
    } else {
      einstellungen.streuscheibe.dropPoint = einstellungen.defaultDropPoint;
    }

    this.eventBus.einstellungenGeaendertEvent.next(einstellungen);
  }

  /**
   * Wenn Argus aktiv,
   * dann Aufgabepunkt und Düngerqualität werden nicht verändert
   *
   * Wenn Argus deaktiv
   * dann optimaler Aufgabepunkt und Düngerqualität auf sehr gut setzen
   * @param einstellungen
   */
  windGeaendert(einstellungen: SimulationsEinstellungen): void {
    if (!einstellungen.argustwin) {
      einstellungen.streuscheibe.dropPoint = einstellungen.defaultDropPoint;
      this.eventBus.duengerQualitaetAufSehrGutEvent.next(true);
    }
    this.eventBus.einstellungenGeaendertEvent.next(einstellungen);
  }

  /**
   * Wenn aktiv,
   * dann optimaler Aufgabepunkt - Düngerqualität berechnen
   *
   * Wenn deaktiviert
   * Wind auf 0 setzen
   * auf optimalen Aufgabepunkt setzen
   * Düngerqualität auf sehr gut setzen
   * @param einstellungen
   */
  argusTwinGeaendert(einstellungen: SimulationsEinstellungen): void {
    if (einstellungen.argustwin) {
      const duengerqualitaetWert = einstellungen.duengerqualitaet ? Number(einstellungen.duengerqualitaet.wert) : 0;
      einstellungen.streuscheibe.dropPoint = this.berechneAufgabepunkt(einstellungen.defaultDropPoint, duengerqualitaetWert);
    } else {
      einstellungen.windgeschwindigkeit = 0;
      einstellungen.windgeschwindigkeitIndex = 0;
      einstellungen.streuscheibe.dropPoint = einstellungen.defaultDropPoint;
      this.eventBus.duengerQualitaetAufSehrGutEvent.next(true);
    }

    this.eventBus.einstellungenGeaendertEvent.next(einstellungen);
  }

  /**
   * Aufgabepunkt berechne aus optimalerAufgabepunkt - duengerqualitaetWert
   * @param optimalerAufgabepunkt
   * @param duengerqualitaetWert
   * @private
   */
  private berechneAufgabepunkt(optimalerAufgabepunkt: number, duengerqualitaetWert: number): number {
    const ergebnis = optimalerAufgabepunkt - duengerqualitaetWert;

    if (ergebnis <= 0) {
      return 0;
    } else if (ergebnis >= 60) {
      return 60;
    } else {
      return ergebnis;
    }
  }
}
